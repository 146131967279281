<template>
    <div class="menu-body"
        v-if="$oidc.isAuthenticated && $oidc.userProfile && $oidc.userProfile.roles && $oidc.userProfile.roles.length > 0">
        <Loader v-if="showLoader" />
        <Breadcrumb />
        <div class="outer-block">
            <div class="tab-titles">
                <div class="tab-options">
                    <div v-for="(roleData, index) in usersRoles" :key="roleData.tabName"
                        class="d-flex gap-closure-header-sub-con">
                        <div class="in-action">
                            <div class="primary-color tab-option-text"
                                :class="{ 'tab-active-option': activeTab === roleData.tabName }"
                                @click="tabSwitch(index, roleData)">
                                {{ roleData.name }}
                            </div>
                            <hr class="active-tab-scroll-hr" v-if="activeTab === roleData.tabName" />
                        </div>
                    </div>
                </div>
            </div>
            <hr class="hr" />

            <div class="main-con" style="margin: 0">
                <div class="mat-info-con">
                    <div class="plant-dd">
                        <label for="pillar" class="form-label select-lable lable-text"> Year </label>
                        <select class="form-control select-text select-text-pd cust-st-icon" id="pillar"
                            v-model="selectedYear" @change="dropdownChanged()">
                            <option v-for="year in yearList" v-bind:key="year" :value="year">
                                {{ year }}
                            </option>
                        </select>
                    </div>
                    <div class="plant-dd" v-if="selectedRole === 'plant'">
                        <label for="pillar" class="form-label select-lable lable-text"> Plant </label>
                        <select class="form-control select-text select-text-pd cust-st-icon" id="pillar" v-model="plantId"
                            @change="plantChanged($event)">
                            <option v-for="plant in plantList" :key="plant.plantId" :value="plant">
                                {{ plant.plantName }}, {{ plant.regionCode }}
                            </option>
                        </select>
                    </div>
                    <div class="plant-dd" v-if="selectedRole === 'regional'">
                        <label for="pillar" class="form-label select-lable lable-text"> Regional </label>
                        <select class="form-control select-text select-text-pd cust-st-icon" id="pillar"
                            v-model="selectedRegion" @change="dropdownChanged()">
                            <option v-for="plant in regionList" :key="plant.regionId" :value="plant.regionId">
                                {{ plant.regionCode }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="distribute-con button-r" v-if="selectedRole === 'plant'">
                    <b-button pill class="primar-btn distribute-btn" v-if="!editingMode" @click="editAOPTarget()"
                        :disabled="disableChngBtn"> Change </b-button>
                    <b-button class="btn ed-st-btn" style="border: transparent; background: transparent; color: #274ba7"
                        pill variant="outline-primary" v-if="editingMode" @click="stopEditing()">
                        Cancel
                    </b-button>
                    <b-button class="asmt-bt button-sm" style="padding: 0.25rem 1rem; margin-left: 1rem" pill
                        variant="outline-primary" @click="saveChanges()" v-if="editingMode">
                        Save
                    </b-button>
                </div>
                <div class="trget-sum">Summary</div>
                <div class="aop-table-class">
                    <b-table id="aop-table" class="target-table gn-table-b" responsive :items="committedValues"
                        :fields="committedFields">
                        <template #head(label)="data">
                            <div>
                                {{ selectedRole === "global" ? "Region" : selectedRole === "regional" ? "Plant" : data.label
                                }}
                            </div>
                        </template>
                        <template #cell()="row">
                            <span v-if="row.field.status === 'N'"></span>
                            <div class="input-div" v-else-if="row.field.key === 'totalCount' && editingMode">
                                {{ calculateTotal(row.item) }}
                            </div>
                            <div class="input-div" style="padding: 0.75rem"
                                v-else-if="row.field.key !== 'label' && row.field.key !== 'totalCount' && editingMode">
                                <input class="input-f aop-input" v-model="row.item[row.field.key]" min="0"
                                    @input="changingF(row)" pattern="^[0-9]" maxlength="5" @keydown="isNumber" />
                            </div>
                            <span v-else
                                :class="{ 'sub-menu-item-active': row.index === committedValues.length - 1 && selectedRole !== 'plant' }">
                                {{ row.item[row.field.key] }}
                            </span>
                        </template>
                    </b-table>
                    <div style="justify-content: center; margin: 2rem 0" class="display-flex"
                        v-if="!committedValues.length">
                        <div style="text-align: center">
                            <img alt="no data" src="@/assets/No_Data.svg" />
                            <div class="ed-date">No Data Found</div>
                        </div>
                    </div>
                </div>
                <div class="last-ud-by" v-if="updatedByUser">
                    Last updated by {{ updatedByUser }} {{ updatedByUserDate | dateFormater }},
                    {{ updatedByUserTime | timeFormatter }} EST
                </div>

                <div id="target-approval-accordion" v-if="selectedRole === 'global' && regionWiseList.length">
                    <div class="accordion" style="border-top: 0.5px solid #eae6e6" role="tablist"
                        v-if="regionWiseList.length">
                        <div v-for="(actionMain, actionMainIndex) in regionWiseList" :key="(actionMain, actionMainIndex)"
                            class="add-top-border">
                            <div :id="actionMain.regionId">
                                <div class="level-status target-card-lable principle-name-label"
                                    @click="checkToggle(collapsed_values[actionMain.regionName], actionMain.regionName)">
                                    <b-button :aria-controls="actionMain.regionName" class="img-button"
                                        :aria-expanded="collapsed_values[actionMain.regionName] ? 'true' : 'false'">
                                        <img alt="down" v-if="!collapsed_values[actionMain.regionName]"
                                            src="@/assets/accordian_down_arrow.svg" />
                                        <img alt="up" v-else src="@/assets/accordian_up_arrow.svg" />
                                    </b-button>
                                    {{ actionMain.regionName }}
                                </div>
                                <b-collapse v-bind:id="actionMain.regionName"
                                    v-model="collapsed_values[actionMain.regionName]">
                                    <div>
                                        <div class="oee-regional-count">
                                            <b-table id="aop-table" class="target-table gn-table-b" responsive
                                                :items="actionMain.committedValues" :fields="committedFields">
                                                <template #cell()="row">
                                                    <span
                                                        :class="{ 'sub-menu-item-active': row.index === actionMain.committedValues.length - 1 && selectedRole !== 'plant' }">
                                                        {{ row.item[row.field.key] }}
                                                    </span>
                                                </template>
                                            </b-table>
                                            <div style="justify-content: center; margin: 2rem 0" class="display-flex"
                                                v-if="!actionMain.committedValues.length">
                                                <div style="text-align: center">
                                                    <img alt="no data" src="@/assets/No_Data.svg" />
                                                    <div class="ed-date">No Data Found</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </b-collapse>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <label class="approval-heading">Approvals</label>
                </div>
                <div class="approval-border-top">
                    <label class="col-6 persona-heading approval-border-right" style="text-align: center"> Regional Approval
                    </label>

                    <label class="col-6 persona-heading approval-border-right" style="text-align: center"> Global Approval
                    </label>
                </div>
                <div class="full-length-width display-flex">
                    <div class="col-6 display-flex approval-border-right">
                        <div class="col-6 no-padding review-background-active customise">
                            <label class="approval-section-heading">
                                Pending Approval
                                <b-form-checkbox id="regional-review" v-model="regionalPending"
                                    @change="regionalCheckBox($event, 'regional-approve')" name="executive" class="radio"
                                    :disabled="!regionalDisabled"></b-form-checkbox>
                            </label>
                        </div>
                        <div class="col-6 no-padding review-background-active">
                            <label class="approval-section-heading">
                                Lock & Approve
                                <b-form-checkbox id="regional-approve" v-model="regionalApprove"
                                    @change="regionalCheckBox($event, 'regional-review')" name="executive" class="radio"
                                    :disabled="!regionalDisabled"></b-form-checkbox>
                            </label>
                        </div>
                    </div>

                    <div class="col-6 display-flex">
                        <div class="col-6 no-padding review-background-active customise">
                            <label class="approval-section-heading">
                                Pending Approval
                                <b-form-checkbox id="global-review" v-model="globalPending"
                                    @change="globalCheckBox($event, 'global-approve')" name="executive" class="radio"
                                    :disabled="!globalDisabled"></b-form-checkbox>
                            </label>
                        </div>
                        <div class="col-6 no-padding review-background-active">
                            <label class="approval-section-heading">
                                Lock & Approve
                                <b-form-checkbox id="global-approve" v-model="globalApprove"
                                    @change="globalCheckBox($event, 'global-review')" name="executive" class="radio"
                                    :disabled="!globalDisabled"></b-form-checkbox>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="full-length-width display-flex">
                    <div class="col-6 display-flex approval-border-right" style="flex-wrap: wrap">
                        <!-- <label class="col-12 approvals-updated-by" v-if="approvedByNamePM && updateByDateTimePM">
                        Updated by {{ approvedByNamePM }} {{ updateByDateTimePM | dateFormater }}, {{ updateByTimePM }} EST
                    </label> -->
                        <div class="col-12 approvals-save-button" v-if="regionalDisabled">
                            <b-button pill variant="outline-secondary" class="save-button save-btn primary-color"
                                @click="approveSaveCall"> Save </b-button>
                        </div>
                    </div>
                    <div class="col-6 display-flex approval-border-right" style="flex-wrap: wrap">
                        <!-- <label class="col-12 approvals-updated-by" v-if="approvedByNameRM && updateByDateRM">
                        Updated by {{ approvedByNameRM }} {{ updateByDateRM | dateFormater }}, {{ updateByTimeRM }} EST
                    </label> -->
                        <div class="col-12 approvals-save-button" v-if="globalDisabled">
                            <b-button pill variant="outline-secondary" class="save-button save-btn primary-color"
                                @click="approveSaveCall"> Save </b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Breadcrumb from "../../../components/common/Breadcrumb.vue";
import Api from "../../../common/constants/api";
import ApiCalls from "../../../common/api/apiCalls";
import Loader from "../../../components/common/Loader.vue";
import Helper from "../../../common/helper";
import moment from "moment";
import Role from "../../../common/constants/roles";
import { mapGetters } from "vuex";
export default {
    name: "AOPTargetSetting",
    components: {
        Breadcrumb,
        Loader
    },

    data() {
        return {
            showLoader: false,
            editingMode: false,
            roles: Role,
            selectedRole: "plant",
            //selectedYear: "2023",
            selectedYear: new Date().getFullYear(),
            yearList: [],
            regionList: [],
            plantList: [],
            plantId: {},
            selectedRegion: 0,
            approveStatus: {},
            committedFields: [
                {
                    key: "label",
                    label: "",
                    sortable: false
                },
                {
                    key: "jan",
                    label: "Jan",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "feb",
                    label: "Feb",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "mar",
                    label: "Mar",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "apr",
                    label: "Apr",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "may",
                    label: "May",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "jun",
                    label: "Jun",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "jul",
                    label: "Jul",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "aug",
                    label: "Aug",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "sep",
                    label: "Sep",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "oct",
                    label: "Oct",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "nov",
                    label: "Nov",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "dec",
                    label: "Dec",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "totalCount",
                    label: "Total",
                    sortable: false
                }
            ],
            committedValues: [],
            collapsed_values: {},
            regionWiseList: [],
            updatedByUser: "",
            updatedByUserDate: "",
            updatedByUserTime: "",
            regionalPending: false,
            regionalApprove: false,
            regionalDisabled: false,
            globalPending: false,
            globalApprove: false,
            globalDisabled: false,
            dropDownDisabled: false,
            usersRoles: [],
            activeTab: "",
            // allLoggedInUserData: [],
            loggedInUserData: {}
        };
    },
    beforeCreate() {
        this.$store.dispatch("selectedMenu", {
            main: 2,
            subMenu: 5
        });
        this.$store.dispatch("breadcrumb", {
            title: "Maturity Model - AOP Target",
            breadcrumb: [
                { name: "Home / ", primary: true, redirect: "/dashboard" },
                { name: "Maturity Model AOP Target", primary: false }
            ]
        });
    },
    computed: {
        ...mapGetters(["plantSelectedInAsmtHome", "prevSelectedWorkCenter", "pillarPrevSelected"]),
        disableChngBtn() {
            if (!!this.committedValues.length) {
                let firstElement = JSON.parse(JSON.stringify(this.committedValues[0]));

                return !(
                    this.plantId.plantId === this.loggedInUserData.plantId &&
                    //Number(this.selectedYear) === new Date().getFullYear() && 
                    firstElement.hasOwnProperty("maAopApproval") &&
                    !firstElement.maAopApproval.isGlobalApproved
                );
            } else return true;
        }
    },
    created() {
        this.getAllUserRoles();
    },
    methods: {
        tabSwitch(index, roleData) {
            this.selectedRole = roleData.tabName;
            this.showLoader = true;
            this.activeTab = roleData.tabName;
            this.committedValues = [];
            this.editingMode = false;
            this.loggedInUserData = roleData;
            this.getPlantList();
        },
        changingF(row) {
            console.log(row);
            return "";
        },
        calculateTotal(rowItem) {
            rowItem.totalCount = Number(rowItem.dec) - Number(rowItem.jan);

            return Number(rowItem.dec) - Number(rowItem.jan);
        },
        isNumber(evt) {
            evt = evt ? evt : window.event;
            let charCode = evt.which ? evt.which : evt.keyCode;
            if (charCode > 31 && isNaN(Number(evt.key)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        regionalCheckBox(event, elementId) {
            document.getElementById(elementId).checked = false;
        },
        globalCheckBox(event, elementId) {
            document.getElementById(elementId).checked = false;
        },
        plantManagerCheckbox(event, id) {
            if (event === true && id === "plant-manager-review" && this.plantManagerReview === true) {
                this.plantManagerReview = false;
            }
            if (event === true && id === "plant-manager-approve" && this.plantManagerApprove === true) {
                this.plantManagerApprove = false;
            }
            this.approveBy = "pm";
        },
        executiveCheckbox(event, id) {
            if (event === true && id === "executive-review" && this.exceutiveReview === true) {
                this.exceutiveReview = false;
            }
            if (event === true && id === "executive-approve" && this.exceutiveApprove === true) {
                this.exceutiveApprove = false;
            }
            this.approveBy = "rm";
        },
        editAOPTarget() {
            this.editingMode = true;
        },
        stopEditing() {
            this.editingMode = false;
        },
        dropdownChanged() {
            this.showLoader = true;
            if (this.selectedRole === "plant") this.getAOPDetails();
            else this.getAopApprovalStatus();
        },
        saveChanges() {
            this.showLoader = true;
            this.editingMode = false;
            const plantValues = this.committedValues[0];
            console.log(
                plantValues,
                plantValues.jan,
                plantValues.feb,
                plantValues.mar,
                plantValues.apr,
                plantValues.may,
                plantValues.jun,
                plantValues.jul,
                plantValues.aug,
                plantValues.sep,
                plantValues.oct,
                plantValues.nov,
                plantValues.dec
            );
            let reqBody = [
                {
                    plantId: this.plantId.plantId,
                    year: this.selectedYear,
                    aopId: plantValues.aopId,
                    jan: Number(plantValues.jan),
                    feb: Number(plantValues.feb),
                    mar: Number(plantValues.mar),
                    apr: Number(plantValues.apr),
                    may: Number(plantValues.may),
                    jun: Number(plantValues.jun),
                    jul: Number(plantValues.jul),
                    aug: Number(plantValues.aug),
                    sep: Number(plantValues.sep),
                    oct: Number(plantValues.oct),
                    nov: Number(plantValues.nov),
                    dec: Number(plantValues.dec),
                    isActive: true
                }
            ];
            ApiCalls.httpCallDetails(Api.UPDATEAOP, "post", reqBody).then((data) => {
                this.dropdownChanged();
            });
        },
        dateSetting() { },
        getAllUserRoles() {
            this.showLoader = true;
            ApiCalls.httpCallDetails(Api.GETALLUSERROLES, "get").then((data) => {
                console.log(data);
                const temp = data.data;
                // for testing purpose list of roles
                // const temp = [
                //     {
                //         "userId": 8,
                //         "roleId": 14,
                //         "roleName": "Energy User",
                //         "plantId": 3,
                //         "regionId": 5,
                //         "pillarId": null,
                //         "plantName": "Indonesia",
                //         "regionName": "AP",
                //         "order": 15
                //     }, {
                //         "userId": 8,
                //         "roleId": 6,
                //         "roleName": "Plant PO Leader",
                //         "plantId": 28,
                //         "regionId": 3,
                //         "pillarId": 1,
                //         "plantName": "Lawton",
                //         "regionName": "NA",
                //         "order": 7
                //     }, {
                //         "userId": 8,
                //         "roleId": 3,
                //         "roleName": "Regional PO Leader",
                //         "plantId": null,
                //         "regionId": 3,
                //         "pillarId": 1,
                //         "plantName": null,
                //         "regionName": "NA",
                //         "order": 7
                //     }, {
                //         "userId": 8,
                //         "roleId": 2,
                //         "roleName": "Global Pillar Leader",
                //         "plantId": null,
                //         "regionId": null,
                //         "pillarId": 1,
                //         "plantName": null,
                //         "regionName": null,
                //         "order": 7
                //     }
                // ];
                this.usersRoles = temp.map((role) => {
                    if (role.roleName === this.roles.GLOBALPILLARLEADERNAME)
                        return {
                            tabName: "global",
                            name: this.roles.GLOBALPILLARLEADERNAME,
                            plantId: role.plantId,
                            regionId: role.regionId,
                            regionName: role.regionName
                        };
                    else if (role.roleName === this.roles.REGIONALPOLEADERNAME) {
                        return {
                            tabName: "regional",
                            name: this.roles.REGIONALPOLEADERNAME,
                            plantId: role.plantId,
                            regionId: role.regionId,
                            regionName: role.regionName
                        };
                    } else if (role.roleName === this.roles.PLANTPOLEADERNAME) {
                        return {
                            tabName: "plant",
                            name: this.roles.PLANTPOLEADERNAME,
                            plantId: role.plantId,
                            regionId: role.regionId,
                            regionName: role.regionName
                        };
                    } else return {};
                });
                this.usersRoles = this.usersRoles.filter((obj) => obj && obj.hasOwnProperty("tabName"));
                this.activeTab = this.usersRoles[0].tabName;
                this.selectedRole = this.usersRoles[0].tabName;
                this.loggedInUserData = this.usersRoles[0];
                // this.$store.dispatch("plantSelectedInAsmtHome", this.usersRoles[0].plantId);
                setTimeout(() => {
                    this.getPlantList();
                }, 100);
            });
        },
        getPlantList() {
            ApiCalls.httpCallDetails(Api.GETAOPYEAR, "get").then((data) => {
                this.yearList = data.data;
            });
            ApiCalls.httpCallDetails(Api.GETPLANTLIST, "get").then((data) => {
                this.plantList = data.data;
                // let tempPlantId = this.plantSelectedInAsmtHome ? this.plantSelectedInAsmtHome : this.plantList[0].plantId;
                // let index = 0;
                // if (this.plantSelectedInAsmtHome) index = this.plantList.findIndex((el) => el.plantId == this.plantSelectedInAsmtHome);
                // else if (this.loggedInUserData.plantId) {
                //     let plantIndex = this.plantList.findIndex((el) => el.plantId == this.loggedInUserData.plantId);
                //     if (plantIndex > -1) {
                //         index = plantIndex;
                //         tempPlantId = this.plantList[index].plantId;
                //     }
                // } else if (this.loggedInUserData.regionName) {
                //     let plantIndex = this.plantList.findIndex((el) => el.regionName == this.loggedInUserData.regionName);
                //     if (plantIndex > -1) {
                //         index = plantIndex;
                //         tempPlantId = this.plantList[index].plantId;
                //     }
                // }

                const index = this.plantList.findIndex((i) => i.plantId === this.loggedInUserData.plantId);
                console.log(index);
                this.plantId = index && index !== -1 ? this.plantList[index] : this.plantList[0];

                this.regionList = [];
                data.data.forEach((plant) => {
                    if (this.regionList.findIndex((region) => region.regionId === plant.regionId) === -1) this.regionList.push(plant);
                });
                this.selectedRegion = this.selectedRole === "regional" ? this.loggedInUserData.regionId : this.regionList[0].regionId;

                // this.$store.dispatch("plantSelectedInAsmtHome", tempPlantId);
                this.dropdownChanged();
            });
        },
        plantChanged(event) {
            this.showLoader = true;
            console.log(event.target.value, this.plantId);
            // this.$store.dispatch("plantSelectedInAsmtHome", event.target.value);
            // this.plantId = this.plantList.find((el) => el.plantId == event.target.value);
            this.dropdownChanged();
        },

        dropdownChanged() {
            this.showLoader = true;
            if (this.selectedRole === "plant") this.getAOPDetails();
            else this.getAopApprovalStatus();
        },
        getAopApprovalStatus() {
            this.approveStatus = {};
            const regionToPass = this.selectedRole === "regional" ? this.selectedRegion : 0;
            ApiCalls.httpCallDetails(`${Api.GETAOPAPPROVALSTATUS}/${this.selectedYear}/${regionToPass}`, "get").then((data) => {
                this.approveStatus = data.data;

                this.getAOPDetails();
            });
        },
        getAOPDetails() {
            // const plantData = this.plantList.find(plant => plant.plantId === this.plantId)
            console.log(this.plantId);
            let url = `${Api.GETAOP}${this.selectedYear}/`;
            if (this.selectedRole === "plant") url += this.plantId.regionId + "/" + this.plantId.plantId;
            else if (this.selectedRole === "regional") url += this.selectedRegion + "/0";
            else if (this.selectedRole === "global") url += "0/0";
            this.showLoader = true;
            this.committedValues = [];
            this.updatedByUser = "";
            this.updatedByUserDate = "";
            this.updatedByUserTime = "";
            this.regionalPending = false;
            this.regionalApprove = false;
            this.globalPending = false;
            this.globalApprove = false;
            ApiCalls.httpCallDetails(`${url}`, "get").then((data) => {
                this.showLoader = false;
                let globalSum = {
                    label: "Global",
                    jan: 0,
                    feb: 0,
                    mar: 0,
                    apr: 0,
                    may: 0,
                    jun: 0,
                    jul: 0,
                    aug: 0,
                    sep: 0,
                    oct: 0,
                    nov: 0,
                    dec: 0,
                    totalCount: 0
                };
                data.data.forEach((region) => {
                    let obj = {};
                    let totalCount = 0;

                    if (this.selectedRole === "plant") {
                        if (region.plantWisAop.length) {
                            obj = region.plantWisAop.find((plant) => plant.plantId === this.plantId.plantId);
                            totalCount = obj.dec - obj.jan;
                            this.updatedByUser = obj.updatedBy;
                            this.updatedByUserDate = obj.updatedDate;
                            this.updatedByUserTime = obj.updatedDate;
                            this.regionalPending = !obj.maAopApproval.isRegionalApproved;
                            this.regionalApprove = obj.maAopApproval.isRegionalApproved;
                            this.globalPending = !obj.maAopApproval.isGlobalApproved;
                            this.globalApprove = obj.maAopApproval.isGlobalApproved;
                        } else {
                            this.committedFields.forEach((header) => {
                                obj[header.key] = 0;
                            });
                            obj["maAopApproval"] = {};
                            obj["maAopApproval"]["isGlobalApproved"] = false;
                            this.regionalPending = false;
                            this.regionalApprove = false;
                            this.globalPending = false;
                            this.globalApprove = false;
                        }
                        obj.label = "AOP";
                        obj.totalCount = totalCount;
                        this.committedValues.push(obj);
                        this.regionalDisabled = false;
                        this.globalDisabled = false;
                    } else if (this.selectedRole === "regional") {
                        this.approveStatus.regional.plantLeft.forEach((leftPlant) => {
                            const plant = region.plantWisAop.find((presentPlant) => presentPlant.plantName === leftPlant.plantName);

                            if (plant) {
                                obj = plant;
                                obj["label"] = plant.plantName;
                                obj["totalCount"] = obj.dec - obj.jan;
                            } else {
                                obj = {
                                    plantName: leftPlant.plantName,
                                    jan: "-",
                                    feb: "-",
                                    mar: "-",
                                    apr: "-",
                                    may: "-",
                                    jun: "-",
                                    jul: "-",
                                    aug: "-",
                                    sep: "-",
                                    oct: "-",
                                    nov: "-",
                                    dec: "-",
                                    label: leftPlant.plantName,
                                    totalCount: ""
                                };
                            }
                            this.committedValues.push(obj);
                        });

                        obj = region.plantWisAop.map((plant) => ({
                            plantName: plant.plantName,
                            jan: plant.jan,
                            feb: plant.feb,
                            mar: plant.mar,
                            apr: plant.apr,
                            may: plant.may,
                            jun: plant.jun,
                            jul: plant.jul,
                            aug: plant.aug,
                            sep: plant.sep,
                            oct: plant.oct,
                            nov: plant.nov,
                            dec: plant.dec,
                            label: plant.plantName,
                            totalCount: plant.dec - plant.jan
                        }));
                        this.committedValues = [...this.committedValues, ...obj];

                        this.committedValues.sort((a, b) => {
                            if (a.label < b.label) return -1;
                            if (a.label > b.label) return 1;
                            else return 0;
                        });
                        this.committedValues.push({
                            label: region.regionName,
                            jan: region.janSum,
                            feb: region.febSum,
                            mar: region.marSum,
                            apr: region.aprSum,
                            may: region.maySum,
                            jun: region.junSum,
                            jul: region.julSum,
                            aug: region.augSum,
                            sep: region.sepSum,
                            oct: region.octSum,
                            nov: region.novSum,
                            dec: region.decSum,
                            totalCount: region.decSum - region.janSum
                        });
                        this.regionalPending = !region.isRegionAprroved;
                        this.regionalApprove = region.isRegionAprroved;
                        this.globalPending = !region.isGlobalApproved;
                        this.globalApprove = region.isGlobalApproved;
                        this.regionalDisabled =
                            this.selectedRole === "regional" &&
                            this.approveStatus.regional.isAllApproved &&
                            !region.isRegionAprroved &&
                            Number(this.selectedYear) === new Date().getFullYear() &&
                            this.selectedRegion === this.loggedInUserData.regionId;
                        this.globalDisabled = false;
                    } else if (this.selectedRole === "global") {
                        globalSum = {
                            label: globalSum.label,
                            jan: globalSum.jan + region.janSum,
                            feb: globalSum.feb + region.febSum,
                            mar: globalSum.mar + region.marSum,
                            apr: globalSum.apr + region.aprSum,
                            may: globalSum.may + region.maySum,
                            jun: globalSum.jun + region.junSum,
                            jul: globalSum.jul + region.julSum,
                            aug: globalSum.aug + region.augSum,
                            sep: globalSum.sep + region.sepSum,
                            oct: globalSum.oct + region.octSum,
                            nov: globalSum.nov + region.novSum,
                            dec: globalSum.dec + region.decSum,
                            totalCount: globalSum.totalCount + region.decSum - region.janSum
                        };
                        this.committedValues.push({
                            label: region.regionName,
                            jan: region.janSum,
                            feb: region.febSum,
                            mar: region.marSum,
                            apr: region.aprSum,
                            may: region.maySum,
                            jun: region.junSum,
                            jul: region.julSum,
                            aug: region.augSum,
                            sep: region.sepSum,
                            oct: region.octSum,
                            nov: region.novSum,
                            dec: region.decSum,
                            totalCount: region.decSum - region.janSum
                        });

                        this.regionalPending = !region.isRegionAprroved;
                        this.regionalApprove = region.isRegionAprroved;
                        this.globalPending = !region.isGlobalApproved;
                        this.globalApprove = region.isGlobalApproved;
                        this.regionalDisabled = false;
                        this.globalDisabled = this.selectedRole === "global" && !region.isGlobalApproved && this.approveStatus.global.isAllApproved;
                    }
                });

                // this.committedValues.sort((a, b) => {
                //     if (a.label < b.label) return -1
                //     if (a.label > b.label) return 1
                //     else 0
                // })
                if (this.selectedRole === "global") {
                    this.committedValues.push(globalSum);
                    this.accordionBuild(data.data);
                }
                this.showLoader = false;
            });
        },
        accordionBuild(rawData) {
            this.regionWiseList = [];
            let obj = {};
            this.committedValues.forEach((data) => {
                let region = rawData.find((regionData) => regionData.regionName === data.label);
                if (region) {
                    let values = this.approveStatus.regional.plantLeft.map((leftPlant) => {
                        const plant = region.plantWisAop.find((presentPlant) => presentPlant.plantName === leftPlant.plantName);
                        if (leftPlant.regionName === data.label) {
                            if (plant) {
                                obj = plant;
                                obj["label"] = plant.plantName;
                                obj["totalCount"] = obj.dec - obj.jan;
                            } else {
                                obj = {
                                    plantName: leftPlant.plantName,
                                    jan: "-",
                                    feb: "-",
                                    mar: "-",
                                    apr: "-",
                                    may: "-",
                                    jun: "-",
                                    jul: "-",
                                    aug: "-",
                                    sep: "-",
                                    oct: "-",
                                    nov: "-",
                                    dec: "-",
                                    label: leftPlant.plantName,
                                    totalCount: ""
                                };
                            }
                            return obj;
                        } else return null;
                    });

                    values = values.filter((i) => i && i.hasOwnProperty("label"));

                    obj = region.plantWisAop.map((plant) => ({
                        plantName: plant.plantName,
                        jan: plant.jan,
                        feb: plant.feb,
                        mar: plant.mar,
                        apr: plant.apr,
                        may: plant.may,
                        jun: plant.jun,
                        jul: plant.jul,
                        aug: plant.aug,
                        sep: plant.sep,
                        oct: plant.oct,
                        nov: plant.nov,
                        dec: plant.dec,
                        label: plant.plantName,
                        totalCount: plant.dec - plant.jan
                    }));

                    values = [...values, ...obj];

                    values.sort((a, b) => {
                        if (a.label < b.label) return -1;
                        if (a.label > b.label) return 1;
                        else return 0;
                    });
                    values.push({
                        label: region.regionName,
                        jan: region.janSum,
                        feb: region.febSum,
                        mar: region.marSum,
                        apr: region.aprSum,
                        may: region.maySum,
                        jun: region.junSum,
                        jul: region.julSum,
                        aug: region.augSum,
                        sep: region.sepSum,
                        oct: region.octSum,
                        nov: region.novSum,
                        dec: region.decSum,
                        totalCount: region.decSum - region.janSum
                    });

                    this.regionWiseList.push({
                        regionName: data.label,
                        committedValues: values.filter((i) => i.hasOwnProperty("label"))
                    });
                }
            });
        },
        checkToggle(collapsedValue, regionId) {
            this.collapsed_values[regionId] = !collapsedValue;
        },
        approveSaveCall() {
            const reqBody = {
                year: new Date().getFullYear(),
                region: this.selectedRole === "global" ? 0 : this.selectedRegion,
                isRegionalApproved: this.selectedRole === "regional" ? this.regionalApprove : false,
                isGlobalApproved: this.selectedRole === "global" ? this.globalApprove : false
            };
            this.showLoader = true;
            ApiCalls.httpCallDetails(Api.SAVEAPPROVALS, "post", reqBody).then((data) => {
                this.dropdownChanged();
            });
        }
    }
};
</script>
<style scoped>
/* tabs */

.tab-titles {
    padding: 0 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tab-options {
    display: flex;
    margin-top: 2rem;
}

.tab-option-text {
    font: 1.16rem "BarlowR", sans-serif;
    cursor: pointer;
}

.in-action {
    margin-right: 1.688em;
}

.tab-active-option {
    font: 1.18rem "BarlowM", sans-serif;
}

.active-tab-scroll-hr {
    border-top: 3px #274ba7 solid;
    width: 20%;
    margin-left: auto;
    margin-right: auto;
    opacity: 1 !important;
    margin-top: 1rem;
}

.select-dd {
    width: 10%;
}

.button-dd {
    display: inline-flex;
}

.outer-block {
    background-color: #fff;
    border-radius: 6px;
}

.hr {
    position: relative;
    top: -26px;
}

/* tabs */

/* accordion */
.oee-regional-count-table {
    width: 100%;
    max-height: 37rem !important;
}

.add-top-border {
    border-top: 1px solid #eae6e6;
    border-bottom: 0.5px solid #eae6e6;
}

.target-card-lable {
    margin-bottom: 0.5rem;
    padding: 0 1.25rem;
}

.principle-name-label {
    margin: 1rem 0;
    vertical-align: middle;
    font-size: 1.33rem;
    font-family: "BarlowM", sans-serif;
    line-height: 1.6666666666666667rem;
}

.img-button {
    cursor: pointer;
    background-color: white;
    border-color: white;
}

.oee-regional-count {
    /* margin-top: 2rem; */
    width: 100%;
}

/* accordion */
.disabled-style-label {
    opacity: 0.65;
}

.mat-info-con {
    padding: 0 2rem;
    display: flex;
    align-items: center;
}

.plant-dd {
    width: 20%;
    margin-right: 1rem;
}

.date-setng {
    width: 12rem;
    height: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cmp-by-txt {
    margin-left: 0.3rem;
}

.level-con {
    padding-left: 15px;
    padding-right: 15px;
}

.level-row {
    text-align: center;
    background: #eff3ff;
    border: 1px solid #ebecef;
    border-radius: 5px 5px 0px 0px;
    padding-top: 1em;
    padding-bottom: 1em;
}

.display-flex {
    justify-content: space-around;
}

.level-status {
    color: #454545;
}

.level-date {
    opacity: 0.7;
}

.cml-by {
    width: 80%;
    font-family: "BarlowSb", sans-serif;
    font-size: 1.333rem;
    color: #d35757;
    text-align: right;
}

.modify-date {
    position: absolute;
    top: 11rem;
    right: 4.5rem;
}

.setng-text {
    margin-left: 1rem;
}

.targt-act-next {
    display: flex;
    justify-content: center;
}

.edit-btn {
    height: 2.2rem;
    padding-top: 0.25rem !important;
    margin-left: 0.5rem;
}

.edit-btn:focus {
    background-color: #274ba7;
}

.distribute-con {
    display: flex;
    justify-content: flex-end;
    padding: 0 2rem;
}

.distribute-btn {
    padding: 0.5rem 2rem;
}

.distribute-btn:focus {
    background-color: #274ba7;
}

.target-act-con {
    display: flex;
}

.trget-sum {
    font-size: 1.333rem;
    font-family: "BarlowSb", sans-serif;
    color: #234372;
    padding: 1rem 2rem;
}

.cmt-count {
    font-family: "BarlowR", sans-serif;
    font-size: 1.333rem;
}

.last-ud-by {
    font-size: 0.916rem;
    font-family: "BarlowR", sans-serif;
    color: #5a5a5a;
    text-align: right;
    padding-right: 2rem;
    padding-bottom: 1rem;
}

@media only screen and (max-width: 767px) {
    .mat-info-con[data-v-dcabda2e] {
        display: block !important;
    }

    .plant-dd[data-v-dcabda2e] {
        width: 100% !important;
    }

    .modify-date {
        top: 37rem !important;
    }

    .main-con {
        margin-top: 5rem;
    }
}

@media only screen and (max-width: 767px) {
    .modify-date {
        top: 40rem !important;
    }
}

.approval-heading {
    color: #234372;
    font-size: 1.3333333333333333rem;
    font-family: "BarlowSb", sans-serif;
    padding: 2rem;
    padding-bottom: 1rem;
    padding-top: 1rem;
}

.persona-heading {
    color: #313131;
    font-size: 1.1666666666666667rem;
    font-family: "BarlowR", sans-serif;
    padding: 2rem;
    padding-bottom: 1rem;
    padding-top: 1rem;
}

.full-length-width {
    width: 100%;
    text-align: center;
}

.approval-section-heading {
    color: #313131;
    font-size: 1.1666666666666667rem;
    font-family: "BarlowR", sans-serif;
}

.approval-section-heading-disabled {
    color: #313131;
    opacity: 0.2;
    font-size: 1.1666666666666667rem;
    font-family: "BarlowR", sans-serif;
}

.no-padding {
    padding: 0;
}

.review-background-active {
    background-color: #f0f7ff;
    border-radius: 6px;
    padding-top: 2em;
    padding-bottom: 2rem;
    max-width: 45%;
}

.review-background-not-selected {
    background-color: #f0f7ff;
    border-radius: 6px;
    padding-top: 2em;
    padding-bottom: 2rem;
    max-width: 45%;
    margin-left: 5%;
}

.approvals-updated-by {
    color: #5a5a5a;
    font-size: 0.9166666666666666rem;
    font-family: "BarlowR", sans-serif;
    text-align: center;
    padding: 1rem;
}

.approvals-save-button {
    padding: 1rem;
    float: right;
    text-align: end;
}

.approval-border-top {
    border-top: 1px solid #f0f0f0;
}

.approval-border-right {
    border-right: 1px solid #f0f0f0;
}

.save-button {
    background-color: #eff3ff !important;
    font-size: 1.1666666666666667rem;
    font-family: "BarlowM", sans-serif;
    width: 5.6rem;
    height: 2.9rem;
    border-color: #869ed8;
}

.save-btn {
    border-color: #869ed8 !important;
    background: #eff3ff 0% 0% no-repeat padding-box !important;
    color: #274ba7 !important;
}

.save-btn:hover {
    border-color: #869ed8 !important;
    color: #274ba7 !important;
    background: #cad0e4 0% 0% no-repeat padding-box !important;
}
</style>
